<template>
  <div v-bind="options" class="columns" :class="modifierClasses">
    <div
      v-for="(column, index) in columns"
      :key="index"
      class="columns__column"
    >
      <content-renderer :sections="column" class="content--nested" featured-image-size="Medium" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnsSection',
  props: {
    /**
     * Bound to the parent columns component as props/attributes
     */
    options: {
      type: Object,
      default: () => {},
    },
    /**
     * An array of arrays of components, each array of components represents
     * a column.
     */
    columns: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    useEqualWidthColumns() {
      const typesThatUseEqualWidthColumns = [
        'CopyItem',
        'Quote',
        'ImageSection',
      ];

      return this.columns.find((column) => column.find((item) => typesThatUseEqualWidthColumns.includes(item.type)));
    },
    modifierClasses() {
      const modifierClasses = [`columns--${this.columns.length}`];

      if (this.useEqualWidthColumns) modifierClasses.push(' columns--equal-width');

      return modifierClasses;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_columns';
</style>

<docs>

This component is used in the ContentRenderer to build out column-based layouts
for complex pages like Homepage and CAS pages where custom section types in the
CMS allow users to layout various components in a column layout.

</docs>
